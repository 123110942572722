.header {
    background-color: var(--pure-color);
    color: var(--primary-theme-color);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width:100%;
}



.navigation {
    display: flex;
    margin: 0 auto;/* Center-align the navigation buttons */
}

.nav-button {
    background: none;
    border: none;
    color: var(--primary-theme-color);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Arial", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.nav-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.nav-link {
    color: var(--primary-theme-color);
    font-weight: bold;
    text-decoration: none;
    padding: 10px 20px;
    /*border: 2px solid #333333;
    border-radius: 5px; */
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
}

.nav-link:hover {
background-color: rgb(234, 234, 234);
color:var(--primary-theme-color);

}

.logo-style{
    width: 150px;
    height: 65px;
}



.home-icon{
    color: var(--primary-theme-color);
    font-size: 22px;
}
.home-icon:hover{
    background-color: #ffffff;
    color: #333333;
}

.nav-links {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    gap: 1rem;
    /* Space between navigation links */
}
.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--pure-color);
}

.the-nav-bar {
    background-color: var(--pure-color) !important;
}