.contact-container {
    margin-top: 200px;
    background-color: var(--secondary-background-color);
    color:var(--secondary-theme-color);
    /* margin-bottom:100px; */
    border-radius:20px;
    /* margin-bottom:100px; */
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

p {
    color:var(--contrast-color) ;
}

/* .contact-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 200px; 
} */




/* .grid-container { */
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    Two columns
    grid-gap: 10px;
    Spacing between grid items */
    /* display:flex;
    flex-direction:column;
} */

.contact-header{
    border-bottom: 1px solid #323232;
}

.contact-container .social-bar a i {
    color: #0097b2;
    /* Set icon color to black */
}

.contact-container .social-bar {
    margin: 0;
}

/* .contact-header{
    color:#0097b2;
} */

