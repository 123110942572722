.App {
  text-align: center;
  overflow-x: hidden;
  overflow-x:hidden;
  padding:0;
  margin:0;
  
}






.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f0f0f0;
}

.main-content {
  /* overflow-x:hidden; */
  margin-top: 75px;
  /* margin-bottom:50px; */
  /* background:
      linear-gradient(to right, rgba(255, 255, 255, 0.1) 1%, transparent 1%) -1px -1px,
      linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1%, transparent 1%) -1px -1px;
    background-color: #f0f0f0;
    background-size: 10px 10px; */
    background-color:var(--background-color);
}

.main-content-wrapper {
  background-color: var(--background-color);
  padding-bottom: 100px;
  /* This creates space between Contact and Footer */
}