.outer-container {
    display: flex;
    flex-wrap: wrap;
}



.P4header {
    color: #0097b2;
}



.image-container {
    background-color: black;
    display: flex;
    width: 50%;
}

p {
    font-size: 16px;
}

a {
    color: black;
    font-weight: 600;
}
.project-card {
    margin-top: 100px;
    padding-bottom: 20px;
    background-color: var(--secondary-background-color);
    color: var(--secondary-theme-color);
    border-radius: 20px;
}
.project-header {
    color: var(--contrast-color);
    border-bottom: 1px solid #323232;
}
.back-link {
    color: var(--contrast-color);
}

h6 {
    color: var(--contrast-color);
    text-decoration: underline;
    text-decoration-color: var(--contrast-color);
}