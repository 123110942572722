body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --background-color:#f0f0f0;
  --secondary-background-color:white;
  --primary-theme-color:#0097b2;
  --secondary-theme-color:rgb(0, 0, 0);
  --tertiary-theme-color:#87CEEB;
  --contrast-color:black;
  --pure-color:white;
  --dull-contrast-color:rgb(80, 80, 80);
}

[data-theme="dark"]{
  --background-color:rgb(25, 34, 47);
  --secondary-background-color:rgb(10, 10, 10);
  --primary-theme-color: #0097b2;
  --secondary-theme-color: #f0f0f0;
  --tertiary-theme-color:#0097b2;/* #87CEEB; */
  --contrast-color:white;
  --pure-color:black;
  --dull-contrast-color:rgb(192, 192, 192);

}