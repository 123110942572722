.about-container {
    padding-top: 100px;
    padding-left:150px;
    padding-right:150px;
    padding-bottom:100px;
    display:flex;
    justify-content:center;
    align-items:center;

}
/* h1 {
    font-size: 36px;
    margin-bottom: 20px;
} */ 

/* .content {
   display: flex;
   align-items: center;
   justify-content: flex-start;
    justify-content: center; 
   gap: 50px;
} */

.show-button {
    margin-left: 0;
    border-radius:10px;
    width:150px;
    height:75px;
    font-size: 18px;
    background-color: var(--contrast-color);
    color:var(--tertiary-theme-color);
    border-color:var(--primary-theme-color);
    font-weight: bold;
    cursor:pointer;
    transition: box-shadow 0.3s
} 

 .show-button:hover{
    transform: scale(1.07);
    box-shadow: 0 4px 8px rgba(94, 203, 233, 0.2);
    border-width: 2px;
    background-color: var(--contrast-color);
    color: #87CEEB;
    border-color:#87CEEB;
} 
 .show-button:active
 {
     transform: scale(1.07);
     box-shadow: 0 4px 8px rgba(94, 203, 233, 0.2);
     border-width: 2px;
     background-color: var(--secondary-background-color) !important; 
     color: #87CEEB !important;
     border-color: #87CEEB !important;
 }

.custom-card{
background-color: var(--secondary-background-color);
color:var(--dull-contrast-color);
border-radius:20px;
padding-bottom:1rem;



}
.image-column{
    padding-left:2rem;
    padding-right:2rem;
}



/* .image-container {
    flex: 1;
    max-width:50%;
    margin-left:50px;
}


.image-container img {
    max-width: 100%;
    height: auto;
} */



.about-header {
    /* background-color:var(--pure-color); */
    border-bottom: 1px solid #323232;
    color:var(--secondary-theme-color);
}


/*
h3 {
    text-align: left;
}

p {
    text-align: left;
}

a.no-underline {
    text-decoration: none;
    font-weight:650;
}

li{
    text-align: left;
} */