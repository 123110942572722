.custom-toggle-btn {
    background-color: transparent;
    color: var(--secondary-theme-color);
    border: 1px solid var(--primary-theme-color);
    transition: background-color 0.3s, color 0.3s;
}

.custom-toggle-btn.selected,
.custom-toggle-btn.selected:hover,
.custom-toggle-btn.selected:focus,
.custom-toggle-btn.selected:active {
    background-color: var(--primary-theme-color) !important;
    color: var(--secondary-theme-color) !important;
    border: 1px solid var(--primary-theme-color) !important;
}

.custom-toggle-btn:hover {
    background-color: var(--tertiary-theme-color) !important;
    color: var(--secondary-theme-color) !important;
    border: 1px solid var(--primary-theme-color) !important;
}