/* Main footer container */
.footer {
    background-color: #000000;
    /* Keep the black background */
    color: #fff;
    padding: 50px 0;
    width: 100%;
    margin: 0;
    overflow: auto;
    /* border: 2px solid red; */
}

/* Footer container */
.footer-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0;
    max-width: 100%;
    /* border: 2px solid blue; */
}

/* Footer social section */
.footer-social {
    text-align: center;
    /* background-color: rgba(255, 0, 0, 0.2); */
    /* border: 2px solid green; */

}

/* Footer nav wrapper */
.footer-nav-wrapper {
    order: 3;
    /* background-color: rgba(0, 255, 0, 0.2); */
    /* border: 2px solid orange; */

}

/* Footer navigation */
.footer-nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: rgba(0, 0, 255, 0.2); */
    /* border: 2px solid yellow; */

}

/* Navigation links */
.footer-nav a {
    color: #fff;
    margin: 0 25px;
    text-decoration: none;
    cursor: pointer;
    /* border: 2px solid purple; */
   
}

/* Social bar */
.footer-social p {
    margin: 0;
    color: #fff;
    /* border: 2px solid cyan; */
   
}

.nav-link {
    padding: 20px;
    /* border: 2px solid magenta; */
   
}