.submit-button{
    background-color: var(--contrast-color);
    color: var(--primary-theme-color);
    border-radius: 7px;
    cursor: pointer;
    font-weight: 550;
}

.submit-button:hover{
    transform:scale(1.1);
    box-shadow: 0 4px 8px rgba(6, 201, 255, 0.271);
    border-color: #87CEEB;
    color:var(--tertiary-theme-color);
}

.message-box{
    font-family: "Arial", sans-serif;
    max-width:650px;
    min-width: 200px;
    height: 100px;
    min-height:25px;
}

.success-message {
    /* background-color: rgb(101, 201, 117); */
    background-color:red;
    display:flex;
    padding:20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    height:200px;
}