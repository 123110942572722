/* .projects-container {
    padding-top:100px;
} */
/* 
.project-gallery {
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
} */


/*.projects-grid {
    display: flex;
    box-shadow: 0 8px 24px rgb(0, 0, 0,0.4);
     border-radius: 20px;
     z-index: 1;
    /* align-items: center; */
    /* gap: 10px; */

    /* width: 800px;
    height:450px;
    margin-top: 20px; */
    /* overflow: hidden; */
    /* background-color:rgba(0, 0, 0, 0.3); 

/* .project-container {
    border:2px solid blue; 
    margin-bottom: 20px;
    position:relative;
    transition: transform 0.3s ease;
     background-color: rgba(19, 19, 19, 0.1);
    width: 100%;
    height: 100%;
    flex-shrink: 0.00;
} */



/* .prev-btn,
.next-btn {
    width: 75px;
    height: 450px;
    margin: 20px 0 0;
    z-index: 1000;
    position:absolute;
    font-size: 50px;
    border: none;
    border-width:1px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0);
    color:#0097b2;
    transition: background-color 0.5s ease;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
} */
/* 
.next-btn:hover,
.prev-btn:hover{
    background-color: rgba(0, 0, 0, 0.087);
    border:groove;
} */


/* .prev-btn {
    left: 20.7%;
    
}

.next-btn {
    left: 73.8%;
    } */

/* .project-link { */
    /* display: block; */
    /* position:absolute;
    top:25%; */
    /* text-decoration: none; */
    /* width: 100%; */
/* } */

.project-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* border-radius: 8px; */
    object-fit: cover;
    flex-shrink: 0;
    flex-grow: 0;
    transition: transform 0.3s ease, translate 0.3s ease-in-out;
    /* box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4); */
}


 .project-image:hover {
    transform: scale(1.1);
}

/* .project-label {
text-align: center;
    color: #87CEEB;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0);
    font-weight: bold;
    z-index: 1;
} */

/* .dot-button {
    all: unset;
    display: block;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    transition: scale 100ms ease-in-out;
} */

/* .dot-button:hover {
    scale: 1.2;
} */

/* .fa-regular {
  height: 100%;
  width: 100%;

} */
/* 
.fa-solid {
    font-weight:900;
} */
/* .dot-button > * {
    stroke: rgb(255, 255, 255);
    fill: rgb(0, 0, 0);
    height: 100%;
    width: 100%;
} */

.carousel-card{
    margin-top:100px;
    margin-bottom:100px;
    padding-bottom:20px;
    background-color: var(--secondary-background-color);
    color:var(--secondary-theme-color);
    border-radius:20px;
}
.custom-title{
    color:var(--dull-contrast-color);
}
h5 {
    color: var(--primary-theme-color);
    background-color:var(--secondary-background-color);
    display:inline-block;
    padding:10px;
    border-radius: 10px;
}

p {
    color: var(--tertiary-theme-color);
}