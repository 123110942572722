/* .popup {
    position: fixed;
    z-index:1;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    justify-content: center;
    align-items: center;
    
} */

/* .text-container {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 900px;
    max-height:750px;
    display:flex;
    /* justify-content: center;
    align-items: center; */
    /* background:
            linear-gradient(to right, rgba(255, 255, 255, 0.1) 1%, transparent 1%) -1px -1px,
            linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1%, transparent 1%) -1px -1px;
        background-color: #f0f0f0;
        background-size: 10px 10px;
    color: #000000;
} */ 

 .close-btn {
    /* position: absolute;
    top: 16px;
    right: 16px; */
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s;
    
} 

.close-btn:hover{
    transform: rotate(90deg) scale(1.05);
}

.fa-solid.fa-circle-xmark{
    font-size: 25px;
    /* color:#000000; */
    color:var(--contrast-color);
}

.download-link{
    position:absolute;
    bottom: 15px;
    left: 40%;
}

a:link {
    color:#0097b2;
}


li {
    font-weight:350;
}

.pop-p {
    font-weight:350;
    /* color:white ; */
    color:var(--contrast-color);
}

h3 {
    color: #0097b2;
}

.text-facts {
    /* border:2px solid #87CEEB; */
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    /* padding:50px; */
}

.text-paragraph {
    /* border: 2px solid red; */
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .modal-content {
    background-color: var(--background-color) !important;
    color: var(--contrast-color) !important;
}

.modal-header,
.modal-footer {
    background-color: var(--secondary-background-color) !important;
    color: var(--contrast-color) !important;
} */
.about-pop-body{
     background-color: var(--secondary-background-color) !important;
    /* background-color:rgb(99, 101, 106);rgb(41, 43, 49); */
     color: var(--secondary-theme-color) !important;
    /* color:white; */
}

.about-pop-head{
     background-color:var(--background-color) !important;
    color:var(--contrast-color) !important; 
}